export const eventParticipants = [
  {
    mitgliedID: 8111,
    vorname: "Emma",
    nachname: "Schmidt",
    mitgliedstatus: "Aktiv",
    anmeldedatum: "2023-12-06T09:12:00.000Z",
  },
  {
    mitgliedID: 8222,
    vorname: "Liam",
    nachname: "Müller",
    mitgliedstatus: "Aktiv",
    anmeldedatum: "2023-12-06T10:15:00.000Z",
  },
  {
    mitgliedID: 8324,
    vorname: "Sophia",
    nachname: "Becker",
    mitgliedstatus: "Aktiv",
    anmeldedatum: "2023-12-06T11:31:00.000Z",
  },
  {
    mitgliedID: 8478,
    vorname: "Noah",
    nachname: "Wagner",
    mitgliedstatus: "Aktiv",
    anmeldedatum: "2023-12-06T12:20:01.000Z",
  },
  {
    mitgliedID: 8320,
    vorname: "Olivia",
    nachname: "Hofmann",
    mitgliedstatus: "Aktiv",
    anmeldedatum: "2023-12-06T13:10:20.000Z",
  },
  {
    mitgliedID: 8167,
    vorname: "Mia",
    nachname: "Schneider",
    mitgliedstatus: "Aktiv",
    anmeldedatum: "2023-12-06T13:30:30.000Z",
  },
  {
    mitgliedID: 9001,
    vorname: "Alexander",
    nachname: "Fischer",
    mitgliedstatus: "Aktiv",
    anmeldedatum: "2023-12-06T15:46:20.000Z",
  },
  {
    mitgliedID: 9002,
    vorname: "Sophie",
    nachname: "Wolf",
    mitgliedstatus: "Aktiv",
    anmeldedatum: "2023-12-06T14:03:08.000Z",
  },
  {
    mitgliedID: 9003,
    vorname: "Leon",
    nachname: "Bauer",
    mitgliedstatus: "Aktiv",
    anmeldedatum: "2023-12-06T16:01:01.000Z",
  },
  {
    mitgliedID: 9004,
    vorname: "Emma",
    nachname: "Koch",
    mitgliedstatus: "Aktiv",
    anmeldedatum: "2023-12-06T07:00:00.000Z",
  },
  {
    mitgliedID: 9005,
    vorname: "Finn",
    nachname: "Schuster",
    mitgliedstatus: "Aktiv",
    anmeldedatum: "2023-12-06T19:02:00.000Z",
  },
  {
    mitgliedID: 9006,
    vorname: "Mila",
    nachname: "Bergmann",
    mitgliedstatus: "Alumnus",
    anmeldedatum: "2023-12-06T20:00:24.000Z",
  },
  {
    mitgliedID: 9007,
    vorname: "Luca",
    nachname: "Werner",
    mitgliedstatus: "Senior",
    anmeldedatum: "2023-12-06T11:09:46.000Z",
  },
  {
    mitgliedID: 9008,
    vorname: "Lina",
    nachname: "Schreiber",
    mitgliedstatus: "Senior",
    anmeldedatum: "2023-12-06T23:00:00.000Z",
  },
  {
    mitgliedID: 9009,
    vorname: "Paul",
    nachname: "Graf",
    mitgliedstatus: "Senior",
    anmeldedatum: "2023-12-06T18:37:00.000Z",
  },
  {
    mitgliedID: 9010,
    vorname: "Emilia",
    nachname: "Baumann",
    mitgliedstatus: "Trainee",
    anmeldedatum: "2023-12-06T05:00:00.000Z",
  },
  {
    mitgliedID: 9011,
    vorname: "Ben",
    nachname: "Lorenz",
    mitgliedstatus: "Trainee",
    anmeldedatum: "2023-12-06T16:01:00.000Z",
  },
  {
    mitgliedID: 9012,
    vorname: "Mia",
    nachname: "Schröder",
    mitgliedstatus: "Trainee",
    anmeldedatum: "2023-12-06T21:07:00.000Z",
  },
];
