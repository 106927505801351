export const schulung = [
  {
    schulungId: 8300,
    schulungsName: "Projektmanagement",
    art: "Pflichtworkshop",
    beschreibung: "",
  },
  {
    schulungId: 8301,
    schulungsName: "Rhetorik & Präsentationstechniken",
    art: "Pflichtworkshop",
    beschreibung: "-",
  },
  {
    schulungId: 8302,
    schulungsName: "Akquise & Verhandlungstechnik",
    art: "Pflichtworkshop",
    beschreibung: "-",
  },
  {
    schulungId: 8303,
    schulungsName: "Kundenorientierung & Service",
    art: "Pflichtworkshop",
    beschreibung: "-",
  },
  {
    schulungId: 8304,
    schulungsName: "Netzwerke",
    art: "Pflichtworkshop",
    beschreibung: "-",
  },
  {
    schulungId: 8305,
    schulungsName: "Qualitätsmanagement",
    art: "Pflichtworkshop",
    beschreibung: "-",
  },
  {
    schulungId: 8324,
    schulungsName: "Storytelling- Workshop",
    art: "Workshop",
    beschreibung: "-",
  },
  {
    schulungId: 8326,
    schulungsName: "Prozessmanagement",
    art: "Workshop",
    beschreibung: "-",
  },
  {
    schulungId: 8928,
    schulungsName: "Beziehungsaufbau",
    art: "Workshop",
    beschreibung: "Pacing and Leading",
  },
  {
    schulungId: 8929,
    schulungsName: "Lego Workshop (Sales)",
    art: "Workshop",
    beschreibung: "Sales Workshop",
  },
  {
    schulungId: 8951,
    schulungsName: "Feedbacktraining",
    art: "Workshop",
    beschreibung: "Vorstandsinterne Schulung",
  },
  { schulungId: 8952, schulungsName: "Kommunikation", art: "Workshop", beschreibung: "-" },
  { schulungId: 8953, schulungsName: "Vertrieb", art: "Workshop", beschreibung: "-" },
  { schulungId: 8954, schulungsName: "Führung", art: "Workshop", beschreibung: "-" },
  {
    schulungId: 8955,
    schulungsName: "Excel | Grundlagen",
    art: "Workshop",
    beschreibung: "Formeln | Bedingte Formatierung | Diagramme",
  },
  {
    schulungId: 8956,
    schulungsName: "Just-in-time Schulung",
    art: "Externer Workshop",
    beschreibung: "Durchgeführt durch die Cars Consulting GmbH",
  },
  {
    schulungId: 8957,
    schulungsName: "Presseworkshop",
    art: "Externer Workshop",
    beschreibung: "Durchgeführt durch von Lightning McQueen",
  },
  {
    schulungId: 8958,
    schulungsName: "Führungs- und Delegationsworkshop",
    art: "Externer Workshop",
    beschreibung: "mit sicker Skills GmbH",
  },
  {
    schulungId: 8959,
    schulungsName: "Goal Setting and Effective Team Management",
    art: "Externer Workshop",
    beschreibung: "durchgeführt von Barkeeper",
  },
];
