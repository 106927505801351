export const mitglied_has_event = [
  {
    event_eventID: 2,
    mitglied_mitgliedID: 8111,
  },
  {
    event_eventID: 2,
    mitglied_mitgliedID: 8222,
  },
  {
    event_eventID: 2,
    mitglied_mitgliedID: 8324,
  },
  {
    event_eventID: 2,
    mitglied_mitgliedID: 8478,
  },
  {
    event_eventID: 2,
    mitglied_mitgliedID: 8320,
  },
  {
    event_eventID: 2,
    mitglied_mitgliedID: 8167,
  },
  {
    event_eventID: 3,
    mitglied_mitgliedID: 8167,
  },
  {
    event_eventID: 9,
    mitglied_mitgliedID: 8167,
  },
  {
    event_eventID: 3,
    mitglied_mitgliedID: 8111,
  },
  {
    event_eventID: 3,
    mitglied_mitgliedID: 8222,
  },
];
