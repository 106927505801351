export const events = [
  {
    eventID: 1,
    eventName: "JBT goes Wandern",
    beschreibung: "Das wandern ist den Müllers Lust",
    datum: "2024-07-30T00:00:00.000Z",
    ende: "2024-07-30T00:00:00.000Z",
    anmeldungvon: "2024-07-25T00:00:00.000Z",
    anmeldungbis: "2024-10-25T00:00:00.000Z",
    ort: "Schwarzwald",
    startZeit: "2024-07-30T07:00:00.000Z",
    endZeit: "2024-07-30T07:00:00.000Z",
    teilnehmerZahl: 5,
    maximaleTeilnehmer: 20,
    ww: false,
    netzwerk: false,
    jbtgoes: true,
  },
  {
    eventID: 2,
    eventName: "JBT goes Halloween Party",
    beschreibung: "Spooky scary skeletons send shivers down your spine.",
    datum: "2024-10-31T00:00:00.000Z",
    ende: "2024-10-31T00:00:00.000Z",
    anmeldungvon: "2024-10-15T00:00:00.000Z",
    anmeldungbis: "2024-10-25T00:00:00.000Z",
    ort: "Schloss",
    startZeit: "2024-10-31T19:00:00.000Z",
    endZeit: "2024-10-31T23:59:59.000Z",
    teilnehmerZahl: 50,
    maximaleTeilnehmer: 100,
    ww: false,
    netzwerk: false,
    jbtgoes: true,
  },
  {
    eventID: 3,
    eventName: "Working Weekend WiSe 23/24",
    beschreibung: "WORK HARD, PARTY HARDER",
    datum: "2024-11-17T00:00:00.000Z",
    ende: "2024-11-19T00:00:00.000Z",
    anmeldungvon: "2024-01-01T00:00:00.000Z",
    anmeldungbis: "2024-02-01T00:00:00.000Z",
    ort: "Berlin",
    startZeit: "2024-11-17T09:00:00.000Z",
    endZeit: "2024-11-19T23:59:00.000Z",
    teilnehmerZahl: null,
    maximaleTeilnehmer: null,
    ww: true,
    netzwerk: true,
    jbtgoes: false,
  },
  {
    eventID: 4,
    eventName: "TED Irgendwo",
    beschreibung: "Veranstaltung mit anderen JEs",
    datum: "2024-11-25",
    ende: "2024-11-26",
    anmeldungvon: "2024-10-17",
    anmeldungbis: "2024-11-17",
    ort: "Irgendwo",
    startZeit: "2024-11-25T10:00:00.000Z",
    endZeit: "2024-11-26T23:59:59.000Z",
    teilnehmerZahl: 20,
    maximaleTeilnehmer: 20,
    ww: false,
    netzwerk: true,
    jbtgoes: false,
  },
  {
    eventID: 5,
    eventName: "Jahreshauptversammlung / Weihnachtsfeier",
    beschreibung: "JHV",
    datum: "2024-12-02",
    ende: "2024-12-02",
    anmeldungvon: "2024-10-20",
    anmeldungbis: "2024-11-28",
    ort: "Schloss Hohenheim",
    startZeit: "2024-12-02T16:00:00.000Z",
    endZeit: "2024-12-02T23:59:59.000Z",
    teilnehmerZahl: null,
    maximaleTeilnehmer: null,
    ww: false,
    netzwerk: false,
    jbtgoes: false,
  },
  {
    eventID: 6,
    eventName: "JBT goes Weihnachten",
    beschreibung: "Ho Ho Ho",
    datum: "2024-12-24T00:00:00.000Z",
    ende: "2024-12-24T00:00:00.000Z",
    anmeldungvon: "2024-12-20T00:00:00.000Z",
    anmeldungbis: "2024-12-20T00:00:00.000Z",
    ort: "Schloss",
    startZeit: "2024-12-24T19:00:00.000Z",
    endZeit: "2024-12-24T23:59:59.000Z",
    teilnehmerZahl: 50,
    maximaleTeilnehmer: 100,
    ww: false,
    netzwerk: false,
    jbtgoes: true,
  },
  {
    eventID: 7,
    eventName: "JBT goes Silvester",
    beschreibung: "3, 2, 1, Frohes Neues!",
    datum: "2024-12-31T00:00:00.000Z",
    ende: "2024-12-31T00:00:00.000Z",
    anmeldungvon: null,
    anmeldungbis: "2024-12-30T00:00:00.000Z",
    ort: "Schloss",
    startZeit: "2024-12-31T19:00:00.000Z",
    endZeit: "2024-12-31T23:59:59.000Z",
    teilnehmerZahl: 50,
    maximaleTeilnehmer: 100,
    ww: false,
    netzwerk: false,
    jbtgoes: true,
  },
  {
    eventID: 8,
    eventName: "JBT goes Fasching",
    beschreibung: "Helau und Alaaf",
    datum: "2024-01-29T20:00:00.000Z",
    ende: "2024-01-29T00:00:00.000Z",
    anmeldungvon: "2024-01-15T00:00:00.000Z",
    anmeldungbis: null,
    ort: "Schloss",
    startZeit: "2024-01-29T19:00:00.000Z",
    endZeit: "2024-01-29T23:59:59.000Z",
    teilnehmerZahl: 50,
    maximaleTeilnehmer: 100,
    ww: false,
    netzwerk: false,
    jbtgoes: true,
  },
  {
    eventID: 9,
    eventName: "Recruiting",
    beschreibung: "Recruiting",
    datum: "2024-02-09T16:30:00.000Z",
    ende: "2024-02-12T00:00:00.000Z",
    anmeldungvon: "2024-01-01T00:00:00.000Z",
    anmeldungbis: "2024-01-09T15:30:00.000Z",
    ort: "Büro",
    startZeit: "2024-02-09T10:00:00.000Z",
    endZeit: "2024-02-12T16:00:00.000Z",
    teilnehmerZahl: 5,
    maximaleTeilnehmer: 10,
    ww: false,
    netzwerk: false,
    jbtgoes: true,
  },
  {
    eventID: 10,
    eventName: "JBT goes Weihnachtsmarkt",
    beschreibung: "Glühwein und Lebkuchen",
    datum: "2024-12-17T00:00:00.000Z",
    ende: null,
    anmeldungvon: "2024-12-10T00:00:00.000Z",
    anmeldungbis: "2024-12-17T00:00:00.000Z",
    ort: "Büro",
    startZeit: "2024-12-17T10:00:00.000Z",
    endZeit: null,
    teilnehmerZahl: 5,
    maximaleTeilnehmer: 10,
    ww: false,
    netzwerk: false,
    jbtgoes: true,
  },
  {
    eventID: 11,
    eventName: "JBT goes Nikolaus",
    beschreibung: "Glühwein und Lebkuchen",
    datum: "2024-10-06T00:00:00.000Z",
    ende: null,
    anmeldungvon: "2024-01-10T00:00:00.000Z",
    anmeldungbis: "2024-12-17T00:00:00.000Z",
    ort: "Büro",
    startZeit: "2024-12-17T10:00:00.000Z",
    endZeit: null,
    teilnehmerZahl: 5,
    maximaleTeilnehmer: 10,
    ww: false,
    netzwerk: false,
    jbtgoes: true,
  },
];
