export const schulungsInstanzFeedback = [
  {
    schulung_schulungID: 6159,
    status: "Abgeschlossen",
    teilnehmerAnzahl: 8,
    gesamt: 1.625,
    fragen: [
      {
        feedbackfrage_feedbackfrageID: 1,
        frageText: "Waren die Ausführungen verständlich und leicht nachvollziehbar?",
        durchschnitt: 1.625,
        bewertungen: [
          {
            schulungsfeedback_has_schulungsfeedbackID: 6700,
            schulungsfeedback_datum: "2023-10-30T00:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6701,
            schulungsfeedback_datum: "2023-10-31T07:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6702,
            schulungsfeedback_datum: "2023-11-01T08:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6703,
            schulungsfeedback_datum: "2023-11-02T09:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6704,
            schulungsfeedback_datum: "2023-11-03T10:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6705,
            schulungsfeedback_datum: "2023-11-04T11:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6706,
            schulungsfeedback_datum: "2023-11-05T12:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6707,
            schulungsfeedback_datum: "2023-11-06T13:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6708,
            schulungsfeedback_datum: "2023-11-07T14:00:00.000Z",
            note: 1,
          },
        ],
      },
      {
        feedbackfrage_feedbackfrageID: 2,
        frageText: "Wie bewertest Du den Medieneinsatz/Übersichtlichkeit?",
        durchschnitt: 2.5,
        bewertungen: [
          {
            schulungsfeedback_has_schulungsfeedbackID: 6700,
            schulungsfeedback_datum: "2023-10-30T00:00:00.000Z",
            note: 3,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6701,
            schulungsfeedback_datum: "2023-10-31T07:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6702,
            schulungsfeedback_datum: "2023-11-01T08:00:00.000Z",
            note: 0,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6703,
            schulungsfeedback_datum: "2023-11-02T09:00:00.000Z",
            note: 0,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6704,
            schulungsfeedback_datum: "2023-11-03T10:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6705,
            schulungsfeedback_datum: "2023-11-04T11:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6706,
            schulungsfeedback_datum: "2023-11-05T12:00:00.000Z",
            note: 3,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6707,
            schulungsfeedback_datum: "2023-11-06T13:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6708,
            schulungsfeedback_datum: "2023-11-07T14:00:00.000Z",
            note: 3,
          },
        ],
      },
      {
        feedbackfrage_feedbackfrageID: 3,
        frageText: "Wie wurden gestellte Fragen beantwortet?",
        durchschnitt: 1.5,
        bewertungen: [
          {
            schulungsfeedback_has_schulungsfeedbackID: 6700,
            schulungsfeedback_datum: "2023-10-30T00:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6701,
            schulungsfeedback_datum: "2023-10-31T07:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6702,
            schulungsfeedback_datum: "2023-11-01T08:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6703,
            schulungsfeedback_datum: "2023-11-02T09:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6704,
            schulungsfeedback_datum: "2023-11-03T10:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6705,
            schulungsfeedback_datum: "2023-11-04T11:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6706,
            schulungsfeedback_datum: "2023-11-05T12:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6707,
            schulungsfeedback_datum: "2023-11-06T13:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6708,
            schulungsfeedback_datum: "2023-11-07T14:00:00.000Z",
            note: 1,
          },
        ],
      },
      {
        feedbackfrage_feedbackfrageID: 4,
        frageText: "Waren die Lernziele der Schulung gut erkennbar?",
        durchschnitt: 1.5,
        bewertungen: [
          {
            schulungsfeedback_has_schulungsfeedbackID: 6700,
            schulungsfeedback_datum: "2023-10-30T00:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6701,
            schulungsfeedback_datum: "2023-10-31T07:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6702,
            schulungsfeedback_datum: "2023-11-01T08:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6703,
            schulungsfeedback_datum: "2023-11-02T09:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6704,
            schulungsfeedback_datum: "2023-11-03T10:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6705,
            schulungsfeedback_datum: "2023-11-04T11:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6706,
            schulungsfeedback_datum: "2023-11-05T12:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6707,
            schulungsfeedback_datum: "2023-11-06T13:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6708,
            schulungsfeedback_datum: "2023-11-07T14:00:00.000Z",
            note: 1,
          },
        ],
      },
      {
        feedbackfrage_feedbackfrageID: 5,
        frageText: "Wie bewertest Du die praktische Relevanz des vermittelten Inhaltes?",
        durchschnitt: 1.5,
        bewertungen: [
          {
            schulungsfeedback_has_schulungsfeedbackID: 6700,
            schulungsfeedback_datum: "2023-10-30T00:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6701,
            schulungsfeedback_datum: "2023-10-31T07:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6702,
            schulungsfeedback_datum: "2023-11-01T08:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6703,
            schulungsfeedback_datum: "2023-11-02T09:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6704,
            schulungsfeedback_datum: "2023-11-03T10:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6705,
            schulungsfeedback_datum: "2023-11-04T11:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6706,
            schulungsfeedback_datum: "2023-11-05T12:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6707,
            schulungsfeedback_datum: "2023-11-06T13:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6708,
            schulungsfeedback_datum: "2023-11-07T14:00:00.000Z",
            note: 1,
          },
        ],
      },
      {
        feedbackfrage_feedbackfrageID: 6,
        frageText: "Waren die Übungen/Cases hilfreich?",
        durchschnitt: 1.5,
        bewertungen: [
          {
            schulungsfeedback_has_schulungsfeedbackID: 6700,
            schulungsfeedback_datum: "2023-10-30T00:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6701,
            schulungsfeedback_datum: "2023-10-31T07:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6702,
            schulungsfeedback_datum: "2023-11-01T08:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6703,
            schulungsfeedback_datum: "2023-11-02T09:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6704,
            schulungsfeedback_datum: "2023-11-03T10:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6705,
            schulungsfeedback_datum: "2023-11-04T11:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6706,
            schulungsfeedback_datum: "2023-11-05T12:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6707,
            schulungsfeedback_datum: "2023-11-06T13:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6708,
            schulungsfeedback_datum: "2023-11-07T14:00:00.000Z",
            note: 1,
          },
        ],
      },
      {
        feedbackfrage_feedbackfrageID: 7,
        frageText: "Waren die Übungen/Cases hilfreich?",
        durchschnitt: 1.5,
        bewertungen: [
          {
            schulungsfeedback_has_schulungsfeedbackID: 6700,
            schulungsfeedback_datum: "2023-10-30T00:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6701,
            schulungsfeedback_datum: "2023-10-31T07:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6702,
            schulungsfeedback_datum: "2023-11-01T08:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6703,
            schulungsfeedback_datum: "2023-11-02T09:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6704,
            schulungsfeedback_datum: "2023-11-03T10:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6705,
            schulungsfeedback_datum: "2023-11-04T11:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6706,
            schulungsfeedback_datum: "2023-11-05T12:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6707,
            schulungsfeedback_datum: "2023-11-06T13:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6708,
            schulungsfeedback_datum: "2023-11-07T14:00:00.000Z",
            note: 1,
          },
        ],
      },
      {
        feedbackfrage_feedbackfrageID: 8,
        frageText: "Waren die Übungen/Cases hilfreich?",
        durchschnitt: 1.5,
        bewertungen: [
          {
            schulungsfeedback_has_schulungsfeedbackID: 6700,
            schulungsfeedback_datum: "2023-10-30T00:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6701,
            schulungsfeedback_datum: "2023-10-31T07:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6702,
            schulungsfeedback_datum: "2023-11-01T08:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6703,
            schulungsfeedback_datum: "2023-11-02T09:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6704,
            schulungsfeedback_datum: "2023-11-03T10:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6705,
            schulungsfeedback_datum: "2023-11-04T11:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6706,
            schulungsfeedback_datum: "2023-11-05T12:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6707,
            schulungsfeedback_datum: "2023-11-06T13:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6708,
            schulungsfeedback_datum: "2023-11-07T14:00:00.000Z",
            note: 1,
          },
        ],
      },
      {
        feedbackfrage_feedbackfrageID: 9,
        frageText: "Waren die Übungen/Cases hilfreich?",
        durchschnitt: 1.5,
        bewertungen: [
          {
            schulungsfeedback_has_schulungsfeedbackID: 6700,
            schulungsfeedback_datum: "2023-10-30T00:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6701,
            schulungsfeedback_datum: "2023-10-31T07:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6702,
            schulungsfeedback_datum: "2023-11-01T08:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6703,
            schulungsfeedback_datum: "2023-11-02T09:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6704,
            schulungsfeedback_datum: "2023-11-03T10:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6705,
            schulungsfeedback_datum: "2023-11-04T11:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6706,
            schulungsfeedback_datum: "2023-11-05T12:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6707,
            schulungsfeedback_datum: "2023-11-06T13:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6708,
            schulungsfeedback_datum: "2023-11-07T14:00:00.000Z",
            note: 1,
          },
        ],
      },
    ],
    referenten: [
      {
        mitglied_mitgliedID: 8111,
        vorname: "Maximilian",
        nachname: " von und zu Mustermann",
        durchschnitt: 1.5,
        bewertungen: [
          {
            schulungsfeedback_has_schulungsfeedbackID: 6700,
            schulungsfeedback_datum: "2023-10-30T00:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6701,
            schulungsfeedback_datum: "2023-10-31T07:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6702,
            schulungsfeedback_datum: "2023-11-01T08:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6703,
            schulungsfeedback_datum: "2023-11-02T09:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6704,
            schulungsfeedback_datum: "2023-11-03T10:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6705,
            schulungsfeedback_datum: "2023-11-04T11:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6706,
            schulungsfeedback_datum: "2023-11-05T12:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6707,
            schulungsfeedback_datum: "2023-11-06T13:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6708,
            schulungsfeedback_datum: "2023-11-07T14:00:00.000Z",
            note: 1,
          },
        ],
      },
      {
        mitglied_mitgliedID: 8222,
        vorname: "Maximilian",
        nachname: "von und zu Mustermann",
        durchschnitt: 1.5,
        bewertungen: [
          {
            schulungsfeedback_has_schulungsfeedbackID: 6700,
            schulungsfeedback_datum: "2023-10-30T00:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6701,
            schulungsfeedback_datum: "2023-10-31T07:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6702,
            schulungsfeedback_datum: "2023-11-01T08:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6703,
            schulungsfeedback_datum: "2023-11-02T09:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6704,
            schulungsfeedback_datum: "2023-11-03T10:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6705,
            schulungsfeedback_datum: "2023-11-04T11:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6706,
            schulungsfeedback_datum: "2023-11-05T12:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6707,
            schulungsfeedback_datum: "2023-11-06T13:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6708,
            schulungsfeedback_datum: "2023-11-07T14:00:00.000Z",
            note: 1,
          },
        ],
      },
      {
        mitglied_mitgliedID: 8167,
        vorname: "Maximilian",
        nachname: " von und zu Mustermann",
        durchschnitt: 1.5,
        bewertungen: [
          {
            schulungsfeedback_has_schulungsfeedbackID: 6700,
            schulungsfeedback_datum: "2023-10-30T00:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6701,
            schulungsfeedback_datum: "2023-10-31T07:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6702,
            schulungsfeedback_datum: "2023-11-01T08:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6703,
            schulungsfeedback_datum: "2023-11-02T09:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6704,
            schulungsfeedback_datum: "2023-11-03T10:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6705,
            schulungsfeedback_datum: "2023-11-04T11:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6706,
            schulungsfeedback_datum: "2023-11-05T12:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6707,
            schulungsfeedback_datum: "2023-11-06T13:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6708,
            schulungsfeedback_datum: "2023-11-07T14:00:00.000Z",
            note: 1,
          },
        ],
      },
    ],
    textFeedback: [
      {
        name: "schulungsniveau",
        frageText:
          "Falls Du das Schulungsniveau als unangemessen bewertest, ist es zu hoch oder zu niedrig? Wie sollte die Schulung dann angepasst werden? Wie sehen Deine Verbesserungsvorschläge aus?",
        kommentare: [
          "Das war eine sehr gute Schulung!",
          "Ich habe viel gelernt.",
          "Die Schulung war sehr interessant.",
        ],
      },
      {
        name: "inhaltlich",
        frageText: "Welche inhaltlichen Aspekte sollten ausgebaut, ergänzt oder gestrichen werden?",
        kommentare: [
          "Das war eine sehr gute Schulung!",
          "Ich habe viel gelernt.",
          "Die Schulung war sehr interessant.",
          "Am Ende war für ein ausführlicheres Feedback der Verhandlungsgespräche leider keine Zeit mehr. Da hätte mich für ein größeres Learning gerne wenigstens nochmal 5-10 Minuten eine Rückmeldung von Thomas und Horst gewünscht. Sonst sehr informativer Workshop :)",
        ],
      },
    ],
    mitgliederOhneFeedback: [],
  },
  {
    schulung_schulungID: 6160,
    status: "Feedback",
    teilnehmerAnzahl: 8,
    gesamt: 1.625,
    fragen: [
      {
        feedbackfrage_feedbackfrageID: 1,
        frageText: "Waren die Ausführungen verständlich und leicht nachvollziehbar?",
        durchschnitt: 1.625,
        bewertungen: [
          {
            schulungsfeedback_has_schulungsfeedbackID: 6700,
            schulungsfeedback_datum: "2023-10-30T00:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6701,
            schulungsfeedback_datum: "2023-10-31T07:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6702,
            schulungsfeedback_datum: "2023-11-01T08:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6703,
            schulungsfeedback_datum: "2023-11-02T09:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6704,
            schulungsfeedback_datum: "2023-11-03T10:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6705,
            schulungsfeedback_datum: "2023-11-04T11:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6706,
            schulungsfeedback_datum: "2023-11-05T12:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6707,
            schulungsfeedback_datum: "2023-11-06T13:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6708,
            schulungsfeedback_datum: "2023-11-07T14:00:00.000Z",
            note: 1,
          },
        ],
      },
      {
        feedbackfrage_feedbackfrageID: 2,
        frageText: "Wie bewertest Du den Medieneinsatz/Übersichtlichkeit?",
        durchschnitt: 2.5,
        bewertungen: [
          {
            schulungsfeedback_has_schulungsfeedbackID: 6700,
            schulungsfeedback_datum: "2023-10-30T00:00:00.000Z",
            note: 3,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6701,
            schulungsfeedback_datum: "2023-10-31T07:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6702,
            schulungsfeedback_datum: "2023-11-01T08:00:00.000Z",
            note: 0,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6703,
            schulungsfeedback_datum: "2023-11-02T09:00:00.000Z",
            note: 0,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6704,
            schulungsfeedback_datum: "2023-11-03T10:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6705,
            schulungsfeedback_datum: "2023-11-04T11:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6706,
            schulungsfeedback_datum: "2023-11-05T12:00:00.000Z",
            note: 3,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6707,
            schulungsfeedback_datum: "2023-11-06T13:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6708,
            schulungsfeedback_datum: "2023-11-07T14:00:00.000Z",
            note: 3,
          },
        ],
      },
      {
        feedbackfrage_feedbackfrageID: 3,
        frageText: "Wie wurden gestellte Fragen beantwortet?",
        durchschnitt: 1.5,
        bewertungen: [
          {
            schulungsfeedback_has_schulungsfeedbackID: 6700,
            schulungsfeedback_datum: "2023-10-30T00:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6701,
            schulungsfeedback_datum: "2023-10-31T07:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6702,
            schulungsfeedback_datum: "2023-11-01T08:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6703,
            schulungsfeedback_datum: "2023-11-02T09:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6704,
            schulungsfeedback_datum: "2023-11-03T10:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6705,
            schulungsfeedback_datum: "2023-11-04T11:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6706,
            schulungsfeedback_datum: "2023-11-05T12:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6707,
            schulungsfeedback_datum: "2023-11-06T13:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6708,
            schulungsfeedback_datum: "2023-11-07T14:00:00.000Z",
            note: 1,
          },
        ],
      },
      {
        feedbackfrage_feedbackfrageID: 4,
        frageText: "Waren die Lernziele der Schulung gut erkennbar?",
        durchschnitt: 1.5,
        bewertungen: [
          {
            schulungsfeedback_has_schulungsfeedbackID: 6700,
            schulungsfeedback_datum: "2023-10-30T00:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6701,
            schulungsfeedback_datum: "2023-10-31T07:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6702,
            schulungsfeedback_datum: "2023-11-01T08:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6703,
            schulungsfeedback_datum: "2023-11-02T09:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6704,
            schulungsfeedback_datum: "2023-11-03T10:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6705,
            schulungsfeedback_datum: "2023-11-04T11:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6706,
            schulungsfeedback_datum: "2023-11-05T12:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6707,
            schulungsfeedback_datum: "2023-11-06T13:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6708,
            schulungsfeedback_datum: "2023-11-07T14:00:00.000Z",
            note: 1,
          },
        ],
      },
      {
        feedbackfrage_feedbackfrageID: 5,
        frageText: "Wie bewertest Du die praktische Relevanz des vermittelten Inhaltes?",
        durchschnitt: 1.5,
        bewertungen: [
          {
            schulungsfeedback_has_schulungsfeedbackID: 6700,
            schulungsfeedback_datum: "2023-10-30T00:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6701,
            schulungsfeedback_datum: "2023-10-31T07:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6702,
            schulungsfeedback_datum: "2023-11-01T08:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6703,
            schulungsfeedback_datum: "2023-11-02T09:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6704,
            schulungsfeedback_datum: "2023-11-03T10:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6705,
            schulungsfeedback_datum: "2023-11-04T11:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6706,
            schulungsfeedback_datum: "2023-11-05T12:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6707,
            schulungsfeedback_datum: "2023-11-06T13:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6708,
            schulungsfeedback_datum: "2023-11-07T14:00:00.000Z",
            note: 1,
          },
        ],
      },
      {
        feedbackfrage_feedbackfrageID: 6,
        frageText: "Waren die Übungen/Cases hilfreich?",
        durchschnitt: 1.5,
        bewertungen: [
          {
            schulungsfeedback_has_schulungsfeedbackID: 6700,
            schulungsfeedback_datum: "2023-10-30T00:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6701,
            schulungsfeedback_datum: "2023-10-31T07:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6702,
            schulungsfeedback_datum: "2023-11-01T08:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6703,
            schulungsfeedback_datum: "2023-11-02T09:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6704,
            schulungsfeedback_datum: "2023-11-03T10:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6705,
            schulungsfeedback_datum: "2023-11-04T11:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6706,
            schulungsfeedback_datum: "2023-11-05T12:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6707,
            schulungsfeedback_datum: "2023-11-06T13:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6708,
            schulungsfeedback_datum: "2023-11-07T14:00:00.000Z",
            note: 1,
          },
        ],
      },
    ],
    referenten: [
      {
        mitglied_mitgliedID: 8111,
        vorname: "Hans-Dieter",
        nachname: "Mustermann",
        durchschnitt: 1.5,
        bewertungen: [
          {
            schulungsfeedback_has_schulungsfeedbackID: 6700,
            schulungsfeedback_datum: "2023-10-30T00:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6701,
            schulungsfeedback_datum: "2023-10-31T07:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6702,
            schulungsfeedback_datum: "2023-11-01T08:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6703,
            schulungsfeedback_datum: "2023-11-02T09:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6704,
            schulungsfeedback_datum: "2023-11-03T10:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6705,
            schulungsfeedback_datum: "2023-11-04T11:00:00.000Z",
            note: 1,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6706,
            schulungsfeedback_datum: "2023-11-05T12:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6707,
            schulungsfeedback_datum: "2023-11-06T13:00:00.000Z",
            note: 2,
          },
          {
            schulungsfeedback_has_schulungsfeedbackID: 6708,
            schulungsfeedback_datum: "2023-11-07T14:00:00.000Z",
            note: 1,
          },
        ],
      },
    ],
    textFeedback: [
      {
        name: "schulungsniveau",
        frageText:
          "Falls Du das Schulungsniveau als unangemessen bewertest, ist es zu hoch oder zu niedrig? Wie sollte die Schulung dann angepasst werden? Wie sehen Deine Verbesserungsvorschläge aus?",
        kommentare: [
          "Das war eine sehr gute Schulung!",
          "Ich habe viel gelernt.",
          "Die Schulung war sehr interessant.",
        ],
      },
      {
        name: "inhaltlich",
        frageText: "Welche inhaltlichen Aspekte sollten ausgebaut, ergänzt oder gestrichen werden?",
        kommentare: [
          "Das war eine sehr gute Schulung!",
          "Ich habe viel gelernt.",
          "Die Schulung war sehr interessant.",
          "Am Ende war für ein ausführlicheres Feedback der Verhandlungsgespräche leider keine Zeit mehr. Da hätte mich für ein größeres Learning gerne wenigstens nochmal 5-10 Minuten eine Rückmeldung von Thomas und Horst gewünscht. Sonst sehr informativer Workshop :)",
        ],
      },
    ],
    mitgliederOhneFeedback: [
      {
        mitgliedID: 8167,
        vorname: "Wolfgang U",
        nachname: "Luft",
      },
      {
        mitgliedID: 8320,
        vorname: "Radhika",
        nachname: "Norton",
      },
      {
        mitgliedID: 8324,
        vorname: "Miruna",
        nachname: "Decker",
      },
    ],
  },
];
