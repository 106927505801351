export const schulungen = [
  {
    schulungID: 8300,
    schulungsname: "Projektmanagement",
    beschreibung: "",
    art: "Pflichtschulung",
    schulungsinstanzID: 6809,
    status: "Anmeldung",
    datum: "2023-10-30T00:00:00.000Z",
    startzeit: "2023-10-30T00:00:00.000Z",
    endzeit: "2023-10-30T00:00:00.000Z",
    anmeldungVon: "2023-10-01T00:00:00.000Z",
    anmeldungBis: "2023-10-30T00:00:00.000Z",
    ort: "Büro",
    teilnehmerZahl: 5,
    maximaleTeilnehmerzahl: 10,
    referenten: [""],
    zielgruppe: [""],
  },
  {
    schulungID: 8301,
    schulungsname: "Präsentationstechnik",
    beschreibung: "",
    art: "Anmeldung",
    schulungsinstanzID: 6802,
    status: "Abgeschlossen",
    datum: "2022-11-03T00:00:00.000Z",
    startzeit: null,
    endzeit: null,
    anmeldungVon: "2022-10-01T00:00:00.000Z",
    anmeldungBis: "2022-10-30T00:00:00.000Z",
    ort: "Büro",
    teilnehmerZahl: 5,
    maximaleTeilnehmerzahl: 10,
    referenten: [""],
    zielgruppe: [""],
  },
  {
    schulungID: 8301,
    schulungsname: "Präsentationstechnik",
    beschreibung: "",
    art: "Anmeldung",
    schulungsinstanzID: 6826,
    status: "Abgeschlossen",
    datum: "2023-11-15T00:00:00.000Z",
    startzeit: "2023-11-15T00:00:00.000Z",
    endzeit: "2023-11-15T00:00:00.000Z",
    anmeldungVon: "2023-10-01T00:00:00.000Z",
    anmeldungBis: "2023-10-30T00:00:00.000Z",
    ort: "Büro",
    teilnehmerZahl: 10,
    maximaleTeilnehmerzahl: 10,
    referenten: [""],
    zielgruppe: [""],
  },
  {
    schulungID: 8325,
    schulungsname: "Angebots- Workshop für Mitglieder",
    beschreibung: "",
    art: "Workshop",
    schulungsinstanzID: 6837,
    status: "Anmeldung",
    datum: "2023-11-30T00:00:00.000Z",
    startzeit: "2023-11-30T00:00:00.000Z",
    endzeit: "2023-11-30T00:00:00.000Z",
    anmeldungVon: "2023-10-01T00:00:00.000Z",
    anmeldungBis: "2023-10-30T00:00:00.000Z",
    ort: "Büro",
    teilnehmerZahl: null,
    maximaleTeilnehmerzahl: null,
    referenten: [""],
    zielgruppe: [""],
  },
];
