export const mitglied_has_schulungsinstanz = [
  {
    schulungsinstanz_schulungsinstanzID: 6158,
    mitglied_mitgliedID: 8111,
    typ: "Teilnehmer",
    datum: "2020-01-01",
    anwesend: 1,
    feedbackAbgegeben: 0,
  },
  {
    schulungsinstanz_schulungsinstanzID: 6158,
    mitglied_mitgliedID: 8222,
    typ: "Teilnehmer",
    datum: "2020-01-01",
    anwesend: 1,
    feedbackAbgegeben: 0,
  },
  {
    schulungsinstanz_schulungsinstanzID: 6158,
    mitglied_mitgliedID: 8324,
    typ: "Teilnehmer",
    datum: "2020-01-01",
    anwesend: 1,
    feedbackAbgegeben: 0,
  },
  {
    schulungsinstanz_schulungsinstanzID: 6158,
    mitglied_mitgliedID: 8478,
    typ: "Teilnehmer",
    datum: "2020-01-01",
    anwesend: 1,
    feedbackAbgegeben: 0,
  },
  {
    schulungsinstanz_schulungsinstanzID: 6158,
    mitglied_mitgliedID: 8320,
    typ: "Teilnehmer",
    datum: "2020-01-01",
    anwesend: 1,
    feedbackAbgegeben: 0,
  },
  {
    schulungsinstanz_schulungsinstanzID: 6158,
    mitglied_mitgliedID: 8167,
    typ: "Teilnehmer",
    datum: "2020-01-01",
    anwesend: 1,
    feedbackAbgegeben: 1,
  },
  {
    schulungsinstanz_schulungsinstanzID: 6158,
    mitglied_mitgliedID: 9001,
    typ: "Teilnehmer",
    datum: "2020-01-01",
    anwesend: 1,
    feedbackAbgegeben: 0,
  },
  {
    schulungsinstanz_schulungsinstanzID: 6158,
    mitglied_mitgliedID: 9002,
    typ: "Teilnehmer",
    datum: "2020-01-01",
    anwesend: 1,
    feedbackAbgegeben: 0,
  },
];
